import React from 'react';
import Layout from '../components/Layout';
import Breadcrums from "../components/Breadcrums";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/levenmas/factura_electro.jpg)`,
                        }}
                    >
                        <h1>Factura Electrónica</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <Breadcrums name="Factura Electrónica"/>
                        <div className="card">
                            <div className="card__content">
                                <p style={{color: '#000'}}>
                                    A partir del 1 de enero de 2018 entró en vigor de manera
                                    obligatoria la generación de la factura electrónica en su
                                    versión 3.3, incrementando los requisitos en el llenado. Es muy
                                    importante generar tus facturas con todos los requisitos, de lo
                                    contrario, generará Multas, así como la No Deducibilidad para
                                    tus clientes.
                                </p>
                                <p style={{color: '#000'}}>
                                    OLVÍDATE DE PERDER TIEMPO EN EL LLENADO DE TUS COMPROBANTES Y
                                    DÉJANOS AYUDARTE, OFRECEMOS LA EMISIÓN DE TUS FACTURAS LO ÚNICO
                                    QUE NECESITAMOS SON TUS CERTIFICADOS DIGITALES. AL FINAL DEL MES
                                    O EN LA FECHA QUE LO REQUIERAS TE MANDAMOS UN REPORTE DE TODAS
                                    LAS FACTURAS EMITIDAS.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
